<template>
  <div class="textmessage">
    <div @click="fhubtn" class="textmessage_top">
      <span>
        <i class="el-icon-arrow-left"></i> 返回&nbsp;/
      </span>
      <span>微信支付</span>
    </div>

    <div class="textmessage_bottom">
      <div class="textmessage_bottom_text">
        <p>微信支付</p>
        <span>全球领先的独立第三方支付平台,致力于为广大用户提供安全快速的电子支付/网上支付/手机支付体验</span>
      </div>

      <div class="textmessage_bottom_input">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
          <el-form-item label="微信应用ID：">
            <el-input clearable v-model.trim="ruleForm['app_id']"></el-input>
            <p>默认填写应用appid，若购买了微信小程序则填写小程序appid</p>
          </el-form-item>
          <el-form-item label="初始化证书：">
            <el-switch v-model="ruleForm['is_init']" disabled></el-switch>
          </el-form-item>
          <el-form-item label="商户ID：">
            <el-input :rows="3" clearable v-model.trim="ruleForm['mch_id']"></el-input>
          </el-form-item>
          <!-- class="textmessage_bottom_3"  -->
          <el-form-item label=" 商户平台私钥证书编码：">
            <el-input clearable v-model.trim="ruleForm['mch_cert_code']" class="diy--big"></el-input>
          </el-form-item>


          <el-form-item label="商户私钥：">
            <el-input type="textarea" :rows="3" v-model.trim="ruleForm['mch_private_key']"></el-input>
          </el-form-item>

          <el-form-item label=" APIv3私钥：">
            <el-input clearable v-model.trim="ruleForm['v3_secret_key']" class="diy--big"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <diy-footer-button v-if="hasPerm(['setting.wechat_pay.update'])">
      <el-button type="primary" @click="edit">保存</el-button>
    </diy-footer-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detalform: 1,
      radio: "1",
      ruleForm: {
        app_id: "", //微信应用ID
        is_init: true, //初始化证书
        mch_cert_code: "", //商户平台私钥证书编码
        mch_id: "", // 商户ID
        mch_private_key: "", //商户私钥
        open: true, //是否开启
        v3_secret_key: "" //APIV3私钥
      },
      rules: {
        "sms.aliyun.access_key_id": [
          {
            required: true,
            message: "请输入阿里云AccessKeyId",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        "sms.aliyun.access_key_secret": [
          {
            required: true,
            message: "请输入阿里云AccessKeySecret",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        "sms.aliyun.sign_name": [
          { required: true, message: "请输入模板签名", trigger: "blur" }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        "sms.aliyun.order_create.template_name": [
          {
            required: true,
            message: "请输入模板名称",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.mentinit()
  },
  methods: {
    mentinit() {
      this.$get(this.$apis.wxment).then(res => {
        if (res.code == 200) {
          this.$set(this, 'ruleForm', {
            app_id: res.data["pay.wechat.app_id"],
            is_init: !res.data["pay.wechat.cert_list"].length > 0,
            mch_cert_code: res.data["pay.wechat.mch_cert_code"],
            mch_id: res.data["pay.wechat.mch_id"],
            mch_private_key: res.data["pay.wechat.mch_private_key"],
            v3_secret_key: res.data["pay.wechat.v3_secret_key"],
            'pay.wechat.open': this.$route.query.type
          })
        } else {
          this.$message.error(res.message);
        }
      })
    },
    edit() {
      const { app_id, is_init, mch_cert_code, mch_id, mch_private_key, v3_secret_key } = this.ruleForm;
      const newObj = {
        "pay.wechat.app_id": app_id,
        "pay.wechat.mch_cert_code": mch_cert_code,
        "pay.wechat.mch_id": mch_id,
        "pay.wechat.mch_private_key": mch_private_key,
        "pay.wechat.v3_secret_key": v3_secret_key,
        is_init
      }
      this.$put(this.$apis.wxment, newObj).then(res => {
        if (res.code == 200) {
          this.$message.success("编辑微信支付配置成功!");
          this.mentinit()
        } else {
          this.$message.error(res.message);
        }
      })
    },
    fhubtn() {
      this.$router.push("/setup/payment");
    }
  }
};
</script>

<style lang="less" scoped>
.textmessage {
  .textmessage_top {
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;

    span:first-child {
      margin-left: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }

    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
    }
  }

  .textmessage_bottom {
    border-radius: 4px;
    background: #fff;
    padding: 32px 32px 196px 56px;
    box-sizing: border-box;

    .textmessage_bottom_text {
      p {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
      }

      span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }

    .textmessage_bottom_input {
      margin-top: 40px;
      margin-left: -55px;

      .el-form.item {}

      p {
        position: absolute;
        top: 34px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }

      .textmessage_bottom_3 {
        margin-top: 50px;
      }
    }
  }

  .addtion_foot {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-left: 160px;
    box-sizing: border-box;
    height: auto;
    border-top: 1px solid #f1f1f1;
    z-index: 6;
    background-color: #fff;

    .addtion_foot-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px !important;
    }
  }
}
</style>